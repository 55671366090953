export const columns = [
  {
    label: 'product',
    field: 'priceTagItem.products.brandName',
    formatRowValue: true,
    formatFn: (value) => {
      if (value && value.priceTagItem && value.priceTagItem.products && value.priceTagItem.products.length) {
        return value.priceTagItem.products[0].brandName;
      }
      return '';
    },
    width: '40%',
    sortable: false,
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'packing',
    field: 'priceTagItem.tagItem.value',
    formatRowValue: true,
    formatFn: (value) => {
      if (value && value.priceTagItem && value.priceTagItem.tagItem) {
        return value.priceTagItem.tagItem.value
      }

      return '';
    },
    width: '10%',
    sortable: false,
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'sku',
    field: 'priceTagItem.sku',
    formatRowValue: true,
    formatFn: (value) => {
      if (value && value.priceTagItem) {
        return value.priceTagItem.sku;
      }

      return '';
    },
    width: '10%',
    sortable: false,
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'price',
    field: 'price',
    formatRowValue: true,
    formatFn: (value) => {
      if (value && value.priceTagItem && value.levels.length > 0) {
        const discount = value.levels[0].value;
        return Math.round(value.priceTagItem.price - discount);
      }

      return '';
    },
    width: '10%',
    sortable: false,
    filterOptions: {
      enabled: false,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'oldPrice',
    field: 'oldPrice',
    formatRowValue: true,
    formatFn: (value) => {
      if (value && value.priceTagItem) {
        return value.priceTagItem.price
      }

      return '';
    },
    width: '10%',
    sortable: false,
    filterOptions: {
      enabled: false,
      placeholder: ' ',
      filterValue: '',
    },
  },
]
