<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <h2>{{ $t('discountProductsList') }}</h2>
      <grid-view-table
        :id="'discount-product'"
        :columns="columns"
        :path="'discount'"
        :extraParams="'&join=priceTagItem&join=priceTagItem.tagItem&join=discountType&join=levels&join=priceTagItem.products&filter=discountType.url||eq||packings&filter=priceTagItem.tagItem.removed||eq||false&filter=priceTagItem.products.removed||eq||false&&filter=priceTagItem.products.generalStatus||eq||1'"
      >
      </grid-view-table>
    </div>
  </div>
</template>

<script>
  import GridViewTable from '../../components/GridViewTable';
  import {columns} from './grid-views/discount-products';

  export default {
    name: 'DiscountProductsList',
    components: {
      GridViewTable,
    },
    data: () => {
      return {
        columns,
      }
    }
  }
</script>
